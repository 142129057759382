<div class="card">
    <!-- ENCABEZADO DE CARD -->
    <div class="card-header" >
        <h4 class="card-title">{{title}}</h4>
        <p class="card-category" *ngIf="subtitle">{{subtitle}}</p>
    </div>
    <div class="card-body">
        <!-- CONTENIDO -->
        <ng-content select="[content]"> </ng-content>
    </div>
</div>