import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginContainerComponent } from './login-container/login-container.component';
import { LoginComponent } from './login/login.component';


const routes: Routes = [
    {
        path: '', component: LoginContainerComponent,
        children: [
            { path: '', component: LoginComponent, data: { title: 'Registro de Usuarios', status: true } },
            // { path: 'tours-new', component: ToursFormsComponent, data: { title: 'Nueva Excursión', status: true } },
            // { path: 'tours-edit', component: ToursEditComponent, data: { title: 'Modificar excursiòn', status: true } },
            // { path: 'tours-detail', component: ToursDetailComponent, data: { title: 'Detalle de excursión', status: true } }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoginRoutingModule { }
