import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class SpanishPaginatorIntlService extends MatPaginatorIntl{
  
  itemsPerPageLabel = 'Elementos por página:';
  nextPageLabel = 'Página siguiente';
  previousPageLabel = 'Página anterior';
  firstPageLabel     = 'Primera página';
  lastPageLabel      = 'Última página';
  
  /*
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    console.log("length ", length)
    console.log("page ", page)
    console.log("pageSize ", pageSize)
    
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // Si no se desea mostrar el "1-20 of 45", puedes devolver simplemente la longitud total.
    return ``;
  };*/
}
