import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardComponent } from './card/card.component';
import { ContainerComponent } from './container/container.component';
import { HeaderComponent } from './header/header.component';
import { MaterialModule } from './material.module';
import { MenuComponent } from './menu/menu.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { DetailsComponent } from './details/details.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BookingService } from './service/booking.service';
import { ListadoComponent } from './listado/listado.component';
import { FormsModule } from '@angular/forms';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { SpanishPaginatorIntlService  } from './service/spanish-paginator-intl.service'
import { NgxPaginationModule } from 'ngx-pagination';

// Define las opciones predeterminadas para los tooltips
const tooltipDefaultOptions: MatTooltipDefaultOptions = {
    position: 'below',
    showDelay: 500,
    hideDelay: 0,
    touchendHideDelay: 0,
};

@NgModule({
    declarations: [
        CardComponent,
        MenuComponent,
        ContainerComponent,
        HeaderComponent,
        ConfirmationComponent,
        DetailsComponent,
        ListadoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        NgxDatatableModule,
        FormsModule,
        //NgxPaginationModule
    ],
    exports: [
        CardComponent,
        MenuComponent,
        ContainerComponent,
        HeaderComponent,
        ListadoComponent
    ],
    providers: [
        BookingService,
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaultOptions },
        // Sobrescribe las etiquetas ARIA para la paginación con traducción al español
        { provide: MatPaginatorIntl, useClass: SpanishPaginatorIntlService },
    ]
})
export class SharedModule { }
