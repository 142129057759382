import { Component, OnInit, Input, TemplateRef, ContentChild } from '@angular/core';

@Component({
    selector: 'ortiz-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

    @Input('card-title') title: string;
    @Input() subtitle: string;
    @Input() statusClass = 'card-header-danger';

    constructor() { }

    ngOnInit() {
    }



}
