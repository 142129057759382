
<div class="content content col-12 col-xs-10 col-sm-9 col-md-8 col-lg-7 col-xl-5" >
    <ortiz-restore-password *ngIf="layoutService.isRestorePass"></ortiz-restore-password>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoad && !layoutService.isRestorePass" class="green-progress"></mat-progress-bar>
    <br>
    <!-- CARD -->
    <ortiz-card class="col-lg-6" style="margin-left: 20%" card-title="Iniciar Sesión" *ngIf="!layoutService.isRestorePass">
        <!-- CONTENIDO CARD -->
        <div content>
            <br>
            <form [formGroup]="loginForm" class="row">
                <mat-form-field class="col-sm-12">
                    <mat-label>Usuario</mat-label>
                    <input matInput formControlName="userAccount" placeholder="Ingrese Usuario">
                </mat-form-field>
                <br>
                <mat-form-field class="col-sm-12">
                    <mat-label>Contraseña</mat-label>
                    <input matInput [type]="'password'" formControlName="userPassword" placeholder="Ingrese contraseña">
                </mat-form-field>
            </form>
            <br>
            <!-- BOTONES DE ACCIONES -->
            <div class="col-12 col-xs-12 col-sm-12" style="text-align: end;">
                <div class="row buttons" style="float: right;">
                    <button mat-raised-button (click)="login()" class="new-buttom button" *ngIf="!isLoad">
                        Ingresar
                    </button>
                    <button mat-raised-button class="new-buttom button" *ngIf="isLoad" [disabled]="!isLoad">
                        Cargando...
                    </button>
                </div>
            </div>
            <br>
        </div>
    </ortiz-card>
</div>
