import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';

@Injectable()
export class RoleGuardService implements CanActivate {

    constructor(
        private auth: AuthService,
        private loginService: LoginService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot) {

        const expectedRole = route.data.expectedRole;

        const user: any = this.loginService.getUser();

        if (!this.auth.isAuthenticated() || user.rol !== expectedRole) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }

}
