<!-- CONTENEDOR LISTADO PRINCIPAL -->
<div class="sidebar-wrapper">
    <div class="logo">
        <!-- CONTENEDOR LOGO -->
        <div class="logo-img" style="margin-top: 10px;">
            <!-- TITULO -->
            <div class="title">
                <span>Centro de Reservas</span>
            </div>
        </div>
        <br>
    </div>
    <br>
    <br>
    <ul class="nav">
        <!-- LISTADO PRINCIPAL -->
        <li *ngFor="let menuItem of menuItems" routerLinkActive #rla="routerLinkActive"
            [ngClass]="rla.isActive? (menuItem.children === null? 'active' : '') : ''"
            [routerLinkActiveOptions]="{exact: true}">
            <a *ngIf="!menuItem.hidden" class="menu-items" [style.display]="menuItem.children === null? 'flex' : 'none'"
                [routerLink]="menuItem.path" role="button"
                [style.color]="rla.isActive? activeFontColor : normalFontColor">
                <mat-icon>{{menuItem.icon}}</mat-icon>
                <div *ngIf="totalOpenExcursion > 0 && menuItem.title === 'Logística'; else isOpenExc">
                    <span matBadge="{{ totalOpenExcursion }}" matBadgeOverlap="false"
                        matBadgeColor="warn" matTooltip="Excursiones sin cerrar">{{menuItem.title}}</span>
                </div>
                <ng-template #isOpenExc>
                    <span>{{menuItem.title}}</span>
                </ng-template>
            </a>
            <!-- SUB LISTADO  -->
            <div *ngIf="!menuItem.hidden && menuItem.children !== null" class="collapse-container">
                <a data-toggle="collapse" [href]="'#'+ menuItem.id" role="button" aria-expanded="false"
                    [attr.aria-controls]="menuItem.id" style="padding: 0 15px;"
                    [ngClass]="rla.isActive? 'parent-active' : ''" class="collapse-a collapsed"
                    [style.color]="normalFontColor">
                    <mat-icon>{{menuItem.icon}}</mat-icon>
                    <span>{{menuItem.title}}</span>
                    <strong class="caret" [style.color]="normalFontColor"></strong>
                </a>
            </div>
        </li>
    </ul>
</div>