<div class="wrapper">
  <div class="sidebar" data-color="danger" data-background-color="white">
    <!-- MENU -->
    <ng-content select="[menu]"> </ng-content>
    <div class="sidebar-background" style="background-color: gray;"></div>
  </div>
  <div class="main-panel">
    <!-- HEADER -->
    <ng-content select="[header]"> </ng-content>
    <!-- CONTENIDO -->
    <ng-content select="[content]"> </ng-content>
    <div *ngIf="isMaps('maps')">
      <!-- FOOTER -->
      <ng-content select="[footer]"> </ng-content>
    </div>
  </div>
</div>