import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaymentDetails } from '../class/payment-details';
import { Booking } from '../class/booking';
import { Pax } from '../class/pax';

@Injectable()
export class BookingService {

    constructor(private http: HttpClient) { }

    getBookingByTourPax(id: number): Observable<any> {
        return this.http.post<any>(`/services/booking/api/Bookings/CNRTReport/${id}`, undefined);
    }

    getBookingByTourPaxPrint(id: number): Observable<Blob> {
        return this.http.get<any>(`/services/booking/api/Bookings/CNRTReportPrint/${id}`, {
            responseType: 'blob' as 'json'
        });
    }
    sendMailToDriverAndGuide(data: any): Observable<any> {
        return this.http.post<any>('services/booking/api/Bookings/ResumePrintReportMail', data);
    }

    getBookingReport(id: number, idUser: number): Observable<any> {
        return this.http.post<any>(`/services/booking/api/Bookings/ResumeReport/${id}/${idUser}`, undefined);
    }

    getBookingResumePrintReport(id: number, idUser: number): Observable<Blob> {
        return this.http.get<any>(`/services/booking/api/Bookings/GetResumePrintReportUser/${id}/${idUser}`, {
            responseType: 'blob' as 'json'
        });
    }

    postPaymentDetails(paymentDetail: PaymentDetails): Observable<PaymentDetails> {
        return this.http.post<PaymentDetails>(`/services/booking/api/PaymentDetails`, paymentDetail);
    }

    getPaymentVoucher(id: number): Observable<any> {
        return this.http.get<any>(`/services/booking/api/PaymentDetails/MailVoucher/${id}`);
    }

    bookingCancel(booking: any): Observable<any> {
        return this.http.post<any>(`/services/booking/api/Bookings/Cancel`, booking);
    }

    getNationalities(): Observable<any> {
        return this.http.get<any>(`/services/booking/api/Paxes/Nationalities`);
    }

    postBooking(booking: Booking): Observable<Booking> {
        return this.http.post<Booking>(`/services/booking/api/Bookings`, booking);
    }

    getSearchDniPassport(dni: string, tipo: boolean): Observable<any> {
        return this.http.get<any>(`/services/booking/api/Paxes/${dni}/${tipo}`);
    }

    savePerson(pax: Pax, idBooking: number, idLayout: number): Observable<any> {
        return this.http.post<any>(`/services/booking/api/Paxes/${idLayout}/${idBooking}`, pax);
    }

    printVoucher(idBooking: number): Observable<any> {
        return this.http.get<any>(`/services/booking/api/PaymentDetails/PrintVoucher/${idBooking}`, {
            responseType: 'blob' as 'json'
        });
    }

    getMercado(id: number): Observable<any> {
        return this.http.post<any>(`/services/booking/api/PaymentHeaders/CreateUpdateLinkMPago/${id}`, id);
    }

    getMailPax(id: number): Observable<any> {
        return this.http.get<any>(`/services/booking/api/Paxes/MailByPax/${id}`);
    }

    postMailMP(mail: any): Observable<any> {
        return this.http.post<any>(`/services/booking/api/PaymentHeaders/MailMPago`, mail);
    }

    getCbu(): Observable<any> {
        return this.http.get<any>(`/services/booking/api/Cbus`);
    }

    getCbuEmail(id: number): Observable<any> {
        return this.http.get<any>(`/services/booking/api/Cbus/${id}`);
    }

    getBookingData(idBooking: number): Observable<any> {
        return this.http.get<any>(`/services/booking/api/Bookings/GetBookingData/${idBooking}`);
    }

    updateBooking(booking: Booking, confirmSendEmail: boolean): Observable<any> {
        return this.http.put<any>(`/services/booking/api/Bookings/Update/${confirmSendEmail}`, booking);
    }

    getlayoutAvailable(idBooking: number): Observable<any> {
        return this.http.get<any>(`/services/booking/api/Bookings/GetLayoutsAvailable/${idBooking}`);
    }

    getHistoryPayment(idBooking: number): Observable<any> {
        return this.http.get<any>(`/services/booking/api/PaymentDetails/${idBooking}`);
    }

    postPaymentRefund(paymentDetail: any): Observable<any> {
        return this.http.post<any>(`/services/booking/api/PaymentDetails/Refund`, paymentDetail);
    }

    postPaymentDiscount(paymentDetail: any): Observable<any> {
        return this.http.post<any>(`/services/booking/api/PaymentDetails/Discount`, paymentDetail);
    }

    getDefaultBooking(idUser: number, pageNumber: any): Observable<any> {
        return this.http.post<any>(`/services/booking/api/Bookings/GetDefaultBooking/${idUser}/${pageNumber}`, undefined);
    }

    getSellers(): Observable<any> {
        return this.http.get<any>(`/services/booking/api/Bookings/getSellers`);
    }

    postFilterBookings(filter: any, pageNumber: any): Observable<any> {
        return this.http.post<any>(`/services/booking/api/Bookings/FilterBookings/${pageNumber}`, filter);
    }

    lockSeats(lock: any): Observable<any> {
        return this.http.post<any>(`/services/booking/api/Bookings/LockSeat`, lock);
    }
    unLockSeats(unlock: any): Observable<any> {
        return this.http.post<any>(`/services/booking/api/Bookings/UnlockSeat`, unlock);
    }
    getLock(idLayout: number): Observable<any> {
        return this.http.get<any>(`/services/booking/api/Bookings/GetLockSeat/${idLayout}`);
    }
    updateStatusLayout(newStatus: any) {
        return this.http.post<any>(`/services/booking/api/Bookings/UpdateStatusLayout`, newStatus);
    }
}
