<!-- Plantilla reutilizable para cargar el listado de guías/choferes como también el listado de trasporte dependiendo de la opción seleccionada-->
<div class="main-content">
  <div class="container-fluid">
    <ortiz-card card-title={{title}}>
      <div content>

        <div class="search-container">
          <div class="search-input-container">
            <input type="text" placeholder="Buscar..." (keyup)="handleKeyUp($event)" [(ngModel)]="searchTerm">
            <span class="search-icon">
              <mat-icon>search</mat-icon>
            </span>
          </div>
          <button mat-raised-button class="search-button" (click)="limpiarFiltro()">Todos</button>
        </div>

        <ng-container *ngIf="cargando">
          <div class="blur-background"></div>
          <mat-spinner class="center-spinner"></mat-spinner>
        </ng-container>
        
        <div class="mat-elevation-z8 table-container table-responsive">
          <table mat-table class="table-sm" [dataSource]="isFilterActive ? filteredDataSource : dataSource">

            <!-- First Column -->
            <ng-container matColumnDef="primera">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div class="name-details-container" *ngIf="layoutService.type === 'Person' || layoutService.type === 'User' || layoutService.type === 'Passenger'">
                  <div class="initials">
                    {{element.firstName.trim().charAt(0).toUpperCase()}}{{element.lastName.trim().charAt(0).toUpperCase()}}
                  </div>
                  <div class="name-details">
                    <div class="full-name">
                      {{element.firstName}} {{element.lastName}}
                    </div>
                    <div class="roles" *ngIf="layoutService.type === 'Person'">
                      <span *ngFor="let role of element.rol" class="role">{{role.description}}</span>
                    </div>
                  </div>
                </div>
                <div class="model-details-container" *ngIf="layoutService.type === 'Transport'">
                  <div class="name-model">
                    {{element.marcaTransport.nombre}} 
                    <span *ngIf="element.marcaTransport.nombre && element.model"> - </span>
                    {{ element.model}}
                  </div>
                  <div class="year-capacity">
                    {{element.year !== null ? element.year : ''}}
                    <span *ngIf="element.year && element.capacity"> - </span>
                    {{element.capacity !== null ? element.capacity : ''}}
                </div>
                </div>
              </td>
            </ng-container>

            <!-- Second Column -->
            <ng-container matColumnDef="segunda">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div class="name-details-container" *ngIf="layoutService.type === 'Person' || layoutService.type === 'User' || layoutService.type === 'Passenger'">
                  <div class="name-details">
                    <div class="full-name">
                      {{element.mobile}}
                    </div>
                    <div class="roles">
                      {{element.email}}
                    </div>
                  </div>
                </div>
                <div class="model-details-container" *ngIf="layoutService.type === 'Transport'">
                  <div class="name-model">
                    {{element.legalPerson.companyName}}
                  </div>
                  <div class="year-capacity">
                    Calif: {{element.generalConditionRank}}
                  </div>
                </div>
              </td>
            </ng-container>


            <!-- Third Column -->
            <ng-container matColumnDef="tercera">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="new-buttom" style="background-color: #1cac67"
                  (click)="abrirFormulario(element)">
                  Ver Detalle
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <mat-paginator [pageSizeOptions]="[20]" showFirstLastButtons aria-label="Select page of periodic elements">
          </mat-paginator>
        </div>
        <button mat-raised-button class="open-button" style="background-color: #1cac67"
          (click)="abrirFormularioVacio()">
          Nuevo
        </button>
      </div>
    </ortiz-card>
  </div>
</div>