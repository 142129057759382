import { Location } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoginService } from 'src/app/auth/providers/login.service';
import { LayoutService } from '../service/layout.service';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'ortiz-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    private listTitles: any[];
    public location: Location;
    public mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    public tempState = [];
    public breadcrumbs: Array<any>;
    menuItems = [];
    totalOpenExcursion = 0;
    esConfiguracion = true;
    constructor(
        location: Location,
        private element: ElementRef,
        private router: Router,
        private route: ActivatedRoute,
        private loginService: LoginService,
        public layoutService: LayoutService
    ) {
    }

    ngOnInit() {

        this.menuItems = [
            {
                path: ['/tours/tours-new'], id: 'tour-new', title: 'Nuevo Servicio', icon: 'filter_hdr', children: null

            },
            {
                path: ['/tours'], id: 'tours', title: 'Programar Servicios', icon: 'assignment', children: null

            },
            {
                path: ['/destination'], id: 'destination', title: 'Servicios Programados', icon: 'map', children: null

            },
            {
                path: ['/logistic'], id: 'logistic', title: 'Logística', icon: 'list', children: null

            },
            {
                path: ['/logistic/previous-bookings'], id: 'previous-bookings', title: 'Reservas', icon: 'event_seat', children: null

            },
            {
                path: ['/administracion'], id: 'administracion', title: 'Configuración', icon: 'add_circle_outline', children:
                    [
                        {
                            path: '/administracion/persona',
                            label: "Guías y Choferes"

                        },
                        {
                            path: '/administracion/pasajero',
                            label: 'Pasajeros'
                        },
                        {
                            path: '/administracion/transporte',
                            label: 'Transporte'
                        },
                        {
                            path: '/administracion/usuario',
                            label: 'Usuarios'
                        }
                    ]

            },
        ];
    }

    logout() {
        this.loginService.logout();
    }

    selectItem(label: any) {
        if (label == 'Guías y Choferes') {
            this.layoutService.isPerson = true;
        }else if(label == 'Transporte'){
            this.layoutService.isTransport = true;
        }else if(label == 'Usuarios'){
            this.layoutService.isUser = true;
        }else if(label == 'Pasajeros'){
            this.layoutService.isPassenger = true;
        }
    }

}
