import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {

    constructor(
        public jwtHelper: JwtHelperService
    ) { }

    /**
     * Check whether the token is expired and return.
     */
    isAuthenticated(): boolean {
        const token = sessionStorage.getItem('token');

        return !this.jwtHelper.isTokenExpired(token);
    }

}
