import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/auth/providers/login.service';
import { LayoutService } from '../../../shared/service/layout.service';
import { message } from '../../../shared/constant/constant';

@Component({
    selector: 'ortiz-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: UntypedFormGroup;
    public user: any;
    public id: any;
    hide = true;
    public idU: number;
    public isLoad: boolean;
    startLoading: any;

    constructor(private router: Router,
        private loginService: LoginService,
        private formBuilder: UntypedFormBuilder,
        private notificationService: NotificationsService,
        public layoutService: LayoutService) { }

    ngOnInit() {
        
        this.loginForm = this.formBuilder.group({
            userAccount: ['', [Validators.required]],
            userPassword: ['', [Validators.required]],
        });
    }

    /**user access control*/
    login() {
        this.isLoad = true;
        if (this.loginForm.valid) {
            this.loginService.authenticate(this.loginForm.value).then((resp: any) => {
                if (resp === null) {
                    this.notificationService.warn('Error', 'Usuario o contraseña incorrecto');
                    this.isLoad = false;
                } else {
                    if (resp.rol !== 'Admin') {
                        this.notificationService.warn('Error', 'Acceso denegado');
                        this.isLoad = false;
                    } else {
                        if(resp.estado){
                            if (resp.rol === 'Admin' && !resp.newPassword) {
                                this.layoutService.isRestorePass = false;
                                this.router.navigate(['/tours']);
                                this.notificationService.success('Éxito', 'Ingresó correctamente');
                                this.isLoad = false;
                            }
                            if(resp.rol === 'Admin' && resp.newPassword){
                                this.layoutService.isRestorePass = true;
                                this.isLoad = false;
                            }
                        }else {
                            this.notificationService.warn('', message.MESSAGE_USER_DISABLED);
                            this.isLoad = false;
                        }
                        
                    }
                }
            });
        } else {
            this.notificationService.warn('Error', 'Debe completar Usuario y contraseña');
            this.isLoad = false;
        }
    }

}
