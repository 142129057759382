import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '../service/layout.service';

@Component({
    selector: 'ortiz-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

    public color = 'transparent';
    public activeFontColor = 'rgba(255,255,255,1)';
    public normalFontColor = 'rgba(0,0,0,.8)';
    public dividerBgColor = 'rgba(0,0,0,.6)';
    public menuItems: any[];
    public test: number;
    public totalOpenExcursion = 0;

    constructor(
        public router: Router,
        private layoutService: LayoutService,
    ) { }

    ngOnInit() {

        /**
         * Items del menu generados de forma dinamica
         */


        this.menuItems = [
            {
                path: ['/tours/tours-new'], id: 'tour-new', title: 'Nueva Excursión', icon: 'filter_hdr', children: null

            },
            {
                path: ['/tours'], id: 'tours', title: 'Programar Excursiones', icon: 'assignment', children: null

            },
            {
                path: ['/destination'], id: 'destination', title: 'Excursiones programadas', icon: 'map', children: null

            },
            {
                path: ['/logistic'], id: 'logistic', title: 'Logística', icon: 'list', children: null

            },
        ];

      /*   this.updateOpenExcursionsForTomorrow();

        this.layoutService.totalOpenExcursion.subscribe((totalOpenExcursion: number) => {
            this.totalOpenExcursion = totalOpenExcursion;
        }); */
    }

    /**
     * Funcion para determinar si un elemento de los submenu se encuentra en estado activo y aplicarle las clases
     * de estilo correctamente, este tambien descolapsa el elemento al que eprtenece el child
     * @param parent path padre de url
     * @param child path hijo de url
     * @param id id del elemento colapsable
     */
    isActiveChildRoute(parent: string, child?: string, id?: string) {
        const activeRoute = this.router.url;
        if (parent && child && activeRoute.includes(`${child}`)) {
            (<any>$(`#${id}`)).collapse('show');
            return true;
        } else if (parent && !child && activeRoute === `/${parent}`) {
            (<any>$(`#${id}`)).collapse('show');
            return true;
        } else {
            return false;
        }
    }

   /*  updateOpenExcursionsForTomorrow() {
        this.layoutService.openExcursionForTomorrow().subscribe((excursions: any) => {
            if (excursions.data) {
                this.totalOpenExcursion = excursions.data.length;
            } else {
                this.totalOpenExcursion = 0;
            }
        }, error => console.error(error));
    } */

}
