import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2Webstorage } from 'ngx-webstorage';
import { NgxPaginationModule } from 'ngx-pagination';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AdnInterceptor } from './@core/adn-interceptor';
import { LoginModule } from './auth/login/login.module';
import { CustomDateAdapter } from './pax/pax/custom-date-adapter';
// import { MAT_DATE_FORMATS, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker'
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

registerLocaleData(es);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        // MatTableModule,
        // MatPaginatorModule,
        SimpleNotificationsModule.forRoot({
            position: ['top', 'center'],
            timeOut: 3000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
            clickIconToClose: true
        }),
        SharedModule,
        MatInputModule,
        Ng2Webstorage.forRoot({ prefix: 'app', separator: '-' }),
        AppRoutingModule,
        LoginModule,
        // SatDatepickerModule,
        // SatNativeDateModule
    ],
    providers: [
        // { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
        { provide: LOCALE_ID, useValue: 'es-ES' },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AdnInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

