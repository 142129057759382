export const person = {
    ID_PERSON: 'idPerson',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    BIRTH_DATE: 'birthDate',
    CUIL: 'cuil',
    DNI: 'dni',
    ADDRESS: 'address',
    EMAIL: 'email',
    MOBILE: 'mobile',
    NATIONALITY: 'nationality',
    ID_IDIOMA_NATIVO: 'idiomaNativo',
    CBU: 'cbu',
    BANCO: 'banco',
    PASSPORT: 'passport',
    NATIVE_LANGUAGE: 'nativeLanguage',
    
}

export const guideDriver = {
   
    ROL: 'rol',
    ID_IDIOMA: 'idIdioma',
    ID_TIPO_VEHICULO: 'idTipoVehiculo',
    VENCIMIENTO_CNRT: 'vencimientoCNRT',
    VENCIMIENTO_CDT: 'vencimientoCDT',
    VENCIMIENTO_CARNET_MUNICIPAL: 'vencimientoCarnetMunicipal',
    CATEGORIA_CARNET_MUNICIPAL: 'categoriaCarnetMunicipal',
    ID_ACTIVIDAD: 'idActividad',
    NUMERO_REGISTRO_GUIA: 'numeroRegistroGuia',
    FECHA_VENCIMIENTO_REGISTRO: 'fechaVencimientoRegistro',
    IS_CASH: 'isCash',
    ID_GUIDE: 'idGuide',
    ID_DRIVER: 'idDriver',
    HAS_CONVENIO: 'hasConvenio',
    IMPORTE_HONORARIOS: 'importeHonorarios',
    VIGENCIA_HONORARIOS_FECHA_DESDE: 'vigenciaHonorariosFechaDesde',
    VIGENCIA_HORORARIOS_FECHA_HASTA: 'vigenciaHonorariosFechaHasta'
}

export const message = {
    MESSAGE_WARNING : 'Debe completar todos los datos del formulario',
    MESSAGE_SUCCESS : 'Éxito',
    BAD_REQUEST : 'BAD_REQUEST',
    MESSAGE_REQUIRED : 'Este campo es requerido',
    MESSAGE_BLANQUEO_SUCCESS: 'Blanqueo éxitoso',
    MESSAGE_RESET_PASS: 'Comparta esta clave: adn.123 al usuario',
    MESSAGE_RESET_PASS_USER_LOGGED_IN: 'Tu clave es adn.123. Por favor cierre sesión',
    MESSAGE_SUCCESS_PASSWORD: 'Contraseña actualizada con éxito',
    MESSAGE_USER_DISABLED: 'Usuario deshabilitado. Comuníquese con el Administrador.'
}

export const user = {
    USER_NAME: 'userName'
}

export const rolPerson = {
    ESTADO: 'status',
    ID_ROL: 'idRol'
}

export const transport = {
    ID_TRANSPORT: 'idTransport',
    PATENT: 'patent',
    COLOR: 'color',
    ID_MARCA_TRANSPORT: 'idMarcaTransport',
    MODEL: 'model',
    YEAR: 'year',
    CAPACITY: 'capacity',
    ID_COMPANY_NAME: 'idCompanyName',
    ID_TIPO_VEHICULO: 'idTipoVehiculo',
    EXPIRATION_DATE_INSURANCE: 'expirationDateInsurance',
    INSURANCE: 'insurance',
    EXPIRATION_DATE_RTV: 'expirationDateRtv',
    GENERAL_CONDITION_RANK: 'generalConditionRank',
    ID_COMPANY: 'idCompany'
}

export const legalInformationTransport = {
    ID_LEGAL_INFORMATION_TRANSPORT: 'idLegalInformationTransport',
    CNRT_ENABLEMENT_NUMBER: 'cnrtEnablementNumber',
    CNRT_ENABLEMENT_EXPIRATION_DATE: 'cnrtEnablementExpirationDate',
    STATE_ENABLEMENT_NUMBER: 'stateEnablementNumber',
    STATE_ENABLEMENT_EXPIRATION_DATE: 'stateEnablementExpirationDate',
    CITY_ENABLEMENT_NUMBER: 'cityEnablementNumber',
    CITY_ENABLEMENT_EXPIRATION_DATE: 'cityEnablementExpirationDate',
}

export const typeOption = {
    PERSON: 'Person',
    TRANSPORT: 'Transport',
    USER: 'User',
    TYPE_DOCUMENT: 'typeDocument',
    PASSENGER: 'Passenger',
    SMELL_DNI: '00000000',
    SMELL_PASSPORT: '0000000000', 
    TITULO_GUIA_CHOFER: "Listado de Guías y Choferes",
    TITULO_TRANSPORTE: "Listado de Trasportes",
    TITULO_USUARIOS: "Listado de Usuarios",
    TITULO_PASAJEROS: "Listado de Pasajeros",
    TYPE_TOUR_PRIVATE: "Privado",
    TYPE_TOUR_REGULAR: "Regular",
    TITLE_REPORT: 'Reporte Logística',
    EXTENSION: '.pdf'
}