import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LayoutService } from '../service/layout.service';
import { Subscription } from 'rxjs';
import { GuideDriver } from '../interface/guide-driver';
import { Transport } from '../interface/transport';
import { User } from '../interface/user';
import { Passenger } from '../interface/passenger';
import { person, typeOption } from '../constant/constant';

@Component({
  selector: 'ortiz-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss']
})
export class ListadoComponent implements OnInit, OnDestroy {

  @Input() type: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  private scrollSubscription: Subscription;

  searchTerm: string = '';
  isFilterActive: boolean = false; // Nueva propiedad para controlar el estado del filtro
  cargando: boolean = true;


  displayedColumns: string[] = ['primera', 'segunda', 'tercera'];
  dataSource = new MatTableDataSource<GuideDriver | Transport | User | Passenger>([]);

  //para el filtro 
  filteredDataSource = new MatTableDataSource<GuideDriver | Transport | User | Passenger>([]);
  title: string = "";

  constructor(public layoutService: LayoutService) {
  }

  ngOnInit(): void {
    
    this.checkType();
    this.onSelectScrollSuscription();
  }

  /**método para verificar si cargar el listado de guías, choferes o el listado de transporte o el de usuarios */
  checkType() {
    if (this.layoutService.type === typeOption.PERSON) {

      this.layoutService.getListGuideAndDriver().subscribe(resp => {
        this.dataSource.data = resp.data;
        this.cargando = false;
        this.title = typeOption.TITULO_GUIA_CHOFER;
      });

    } else if (this.layoutService.type === typeOption.TRANSPORT) {
    
      this.layoutService.getListDataTransport().subscribe(resp => {
        this.dataSource.data = resp.data;
        this.title = typeOption.TITULO_TRANSPORTE;
        this.cargando = false;
      })
    } else if (this.layoutService.type === typeOption.USER) {
      this.layoutService.getListDataUser().subscribe( resp => {
        this.dataSource.data = resp.data;
        this.title = typeOption.TITULO_USUARIOS;
        this.cargando = false;
      })
    }else if(this.layoutService.type === typeOption.PASSENGER){
      this.layoutService.getListPassenger().subscribe(resp => {
        this.dataSource.data = resp.data;
        this.title = typeOption.TITULO_PASAJEROS;
        this.cargando = false;
      })
    }
  }


  /** suscripción al evento de desplazamiento*/
  onSelectScrollSuscription() {

    this.scrollSubscription = this.layoutService.selectedScroll$.subscribe(() => {

      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  ngOnDestroy(): void {
    // Desuscríbete para evitar pérdidas de memoria
    this.scrollSubscription.unsubscribe();
  }



  /**metodo que se activa para el filtro */
  handleKeyUp(event: KeyboardEvent) {
    // Activar el filtro al escribir en el input 
    this.isFilterActive = true;
    this.applyFilter();
  }

  /**Método para filtrar la lista según lo ingresado en el input */
  applyFilter(): void {
    const filterValue = this.searchTerm.trim().toLowerCase();
    if (this.layoutService.type === typeOption.PERSON) {
      // Filtrar para tipo 'Person'
      this.filteredDataSource.data = this.dataSource.data.filter((element: GuideDriver) => {
        // Define las propiedades por las que quieres filtrar
        return (
          element.firstName?.toLowerCase().includes(filterValue) ||
          element.lastName?.toLowerCase().includes(filterValue)
        );
      });
    } else if (this.layoutService.type === typeOption.TRANSPORT) {
      // Filtrar para tipo 'Transport'
      this.filteredDataSource.data = this.dataSource.data.filter((element: Transport) => {
        // Define las propiedades por las que quieres filtrar
        return (
          element.marcaTransport.nombre?.toLowerCase().includes(filterValue) ||
          element.model?.toLowerCase().includes(filterValue) ||
          element.year?.toString().includes(filterValue) ||
          element.legalPerson.companyName?.toLowerCase().includes(filterValue)
        );
      });
    }else if(this.layoutService.type === typeOption.USER){
      // Filtrar para tipo 'User'
      this.filteredDataSource.data = this.dataSource.data.filter((element: User) => {
        // Define las propiedades por las que quieres filtrar
        return (
          element.firstName?.toLowerCase().includes(filterValue) ||
          element.lastName?.toLowerCase().includes(filterValue)
        );
      });
    }else if(this.layoutService.type === typeOption.PASSENGER){
      // Filtrar para tipo 'User'
      this.filteredDataSource.data = this.dataSource.data.filter((element: User) => {
        // Define las propiedades por las que quieres filtrar
        return (
          element.firstName?.toLowerCase().includes(filterValue) ||
          element.lastName?.toLowerCase().includes(filterValue)
        );
      });
    }
  }

  limpiarFiltro(): void {
    this.searchTerm = '';
    // Desactivar el filtro al hacer clic en "Todos"
    this.isFilterActive = false;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

    //para el filtro
    this.filteredDataSource.paginator = this.paginator;
    this.paginator.pageSize = 20;
  }

  //**método para abrir el formulario con los datos del usuario seleccionado del listado, el presionar el botón ver detalle*/
  abrirFormulario(element: any) {
    this.layoutService.isPerson = false;
    this.layoutService.isTransport = false;
    this.layoutService.isUser = false;
    this.layoutService.isNewUser = false;
    this.layoutService.isPassenger = false;
    this.layoutService.setSelectedPerson(element);
    this.layoutService.setSelectedTransport(element);
    this.layoutService.setSelectedUser(element);
    this.layoutService.setSelectedPassenger(element);
  }

  /**método para abrir un formulario vacio, al presionar en el botón nuevo */
  abrirFormularioVacio() {
    this.layoutService.isPerson = false;
    this.layoutService.isTransport = false;
    this.layoutService.isUser = false;
    this.layoutService.isNewUser = true;
    this.layoutService.isPassenger = false;
    this.layoutService.setSelectedRol([{ idRol: 5, description: "Driver" }]);
    this.layoutService.setSelectedNewTransport('');
    this.layoutService.setSelectedEstadoUser('');
    this.layoutService.setSelectedTypeDocument(person.DNI);
    
  }

}



