import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { message } from 'src/app/shared/constant/constant';
import { LayoutService } from '../../../shared/service/layout.service';

@Component({
  selector: 'ortiz-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent implements OnInit{
  public restorPassForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private layoutService: LayoutService, private notificationService: NotificationsService){}

  ngOnInit(){
     this.restorPassForm = this.getFormGroup();
  }

  getFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      oldPass:  ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      repeatNewPassword: ['', [Validators.required]]
    }, { validators: this.passwordMatchValidator });
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const oldPass = formGroup.get('oldPass').value;
    const newPassword = formGroup.get('newPassword').value;
    const repeatNewPassword = formGroup.get('repeatNewPassword').value;
  
    // Verificar si la clave generada coincide con la nueva contraseña
    if (oldPass === newPassword) {
      formGroup.get('newPassword').setErrors({ mismatch: true });
    } else {
      // Limpiar el error si no coinciden
      formGroup.get('newPassword').setErrors(null);
    }
  
    // Verificar si la nueva contraseña coincide con la confirmación de contraseña
    if (newPassword !== repeatNewPassword) {
      formGroup.get('repeatNewPassword').setErrors({ mismatch: true });
    } else {
      // Limpiar el error si coinciden
      formGroup.get('repeatNewPassword').setErrors(null);
    }
  }
  
  /**método que envia al backend la nueva contraseña a guardar */
  volver(){
    const currentUser = JSON.parse(sessionStorage.getItem('user'));
 
    const body = {
      'idUser': currentUser.idUser,
      'updatedPassword': this.restorPassForm.get('newPassword').value,
      'newPassword' : false
      }
    this.layoutService.resetNewPassword(body).subscribe(resp =>{
      this.notificationService.success(message.MESSAGE_SUCCESS, message.MESSAGE_SUCCESS_PASSWORD);
    })
    this.layoutService.isRestorePass = false;
  }
}
