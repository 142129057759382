<ortiz-card class="col-lg-6" style="margin-left: 20%" card-title="Restablecer Contraseña">
    <div content>
        <form [formGroup]="restorPassForm" class="row">
            <mat-form-field class="col-sm-12">
                <mat-label>Clave generada</mat-label>
                <input matInput formControlName="oldPass" placeholder="Ingrese clave">
            </mat-form-field>

            <mat-form-field class="col-sm-12">
                <mat-label>Nueva Contraseña</mat-label>
                <input matInput [type]="'password'" formControlName="newPassword"
                    placeholder="Ingrese contraseña nueva">
            </mat-form-field>
            <mat-form-field class="col-sm-12">
                <mat-label>Confirmar Contraseña</mat-label>
                <input matInput [type]="'password'" formControlName="repeatNewPassword"
                    placeholder="Ingrese confirmación contraseña">
            </mat-form-field>
            <div class="message">
                <mat-error
                    *ngIf="restorPassForm.get('newPassword').hasError('mismatch') && restorPassForm.get('newPassword').dirty">
                    La nueva contraseña no puede ser igual a la clave generada.
                </mat-error>
                <mat-error
                    *ngIf="restorPassForm.get('repeatNewPassword').hasError('mismatch') && restorPassForm.get('repeatNewPassword').dirty">
                    Las contraseñas no coinciden.
                </mat-error>
            </div>

        </form>
        <!-- BOTONES DE ACCIONES -->
        <div class="row" style="text-align: center; padding: 40px 0 22px 0;">
            <div class="col">
                <button mat-raised-button type="button" class="button" (click)="volver()">
                    <mat-icon>close</mat-icon> Cancelar
                </button>&nbsp;
                <button mat-raised-button class="new-buttom" style="background-color: #1cac67" [disabled]="!restorPassForm.valid" (click)="volver()">
                    <mat-icon>done</mat-icon> Aceptar
                </button>
            </div>
        </div>
    </div>
</ortiz-card>