<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark p-2" style="z-index: 1000;">

    <div class="row justify-content-between">
        <div class="col" style="text-align: left;">
            <a class="navbar-brand" style="color: #ffffff;">Central de Reservas</a>
        </div>
        <div class="col" style="text-align: right;">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
    </div>

    <div class="collapse navbar-collapse" id="navbarNavDropdown" style="width: 100%;">

        <ul class="navbar-nav">
            <li class="nav-item px-2 letter-size" *ngFor="let menuItem of menuItems" routerLinkActive
                #rla="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
                <a *ngIf="menuItem.title != 'Configuración'" [routerLink]="menuItem.path" class="nav-link" [ngStyle]="{ 'color' : rla.isActive ? 'white' : ''}">
                    <span matBadge="{{ layoutService.getOpenExcursionsTotal() }}" matBadgePosition="after"
                        matBadgeOverlap="false" matBadgeColor="warn" [matBadgeHidden]="menuItem.title !== 'Logística'">
                        <mat-icon>{{ menuItem.icon }}</mat-icon> {{menuItem.title}}
                    </span>
                </a>
                <button style="padding-top: 1px;" *ngIf="menuItem.title == 'Configuración'" mat-button class="nav-link" [matMenuTriggerFor]="menu" [ngStyle]="{ 'color' : rla.isActive ? 'white' : ''}">
                    <mat-icon>{{ menuItem.icon }}</mat-icon> {{menuItem.title}}
                </button>
                <mat-menu #menu="matMenu">
                    <a mat-menu-item *ngFor="let item of menuItem.children" class="nav-link" (click)="selectItem(item.label)" [routerLink]="item.path">{{item.label}}</a>
                </mat-menu>
            </li>
            
        </ul>

        <ul class="navbar-nav ml-auto" style="font-size: 17px;">

            <li class="nav-item">
                <a style="cursor: pointer;" class="nav-link" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon> Cerrar Sesión
                </a>
            </li>

        </ul>
    </div>
</nav>