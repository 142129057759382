import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/shared/service/layout.service';

@Injectable()
export class LoginService {
    public user: any;

    constructor(
        private http: HttpClient,
        private router: Router,
        private layoutService: LayoutService) { }


    authenticate(data: any) {

        return new Promise((resolve, reject) => {
            this.login(data).subscribe(resp => {
                this.user = resp.data;

                if (resp.data) {
                    this.setUser(resp.data);
                    this.setToken(resp.data.jwt);
                    this.toursAlert();
                    resolve({ idUser: resp.data.idUser, userAccount: resp.data.userAccount, rol: resp.data.rol, estado: resp.data.estado, newPassword: resp.data.newPassword });
                } else {
                    resolve(null);
                }
            }, error => reject(error));
        });
    }

    /**validate user */
    login(login): Observable<any> {
        return this.http.post<any>(`/services/login/api/Users/Validate`, login);
    }

    getUser() {
        return JSON.parse(sessionStorage.getItem('user'));
    }

    setUser(user: any) {
        sessionStorage.setItem('user', JSON.stringify(user));
    }

    clearUser() {
        sessionStorage.removeItem('user');
    }

    getToken() {
        return sessionStorage.getItem('token');
    }

    setToken(token: string) {
        sessionStorage.setItem('token', token);
    }

    clearToken() {
        sessionStorage.removeItem('token');
    }

    logout() {
        this.clearUser();
        this.clearToken();
        this.layoutService.isRestorePass = false;
        this.router.navigate(['/login']);
    }

    toursAlert() {
        forkJoin(
            this.layoutService.getToursToday(2),
            this.layoutService.openExcursionForTomorrow()
        ).subscribe(
            ([toursToday, toursTomorrow]: any) => {
                this.layoutService.setOpenExcursionsTotal(0);
                this.layoutService.setOpenExcursionToday(0);
                this.layoutService.setOpenExcursionTomorrow(0);

                let todaySize = this.filterTourToday(toursToday.data ? toursToday.data : []);
                let tomorrowSize = toursTomorrow.data ? toursTomorrow.data.length : 0;

                this.layoutService.setOpenExcursionToday(todaySize);
                this.layoutService.setOpenExcursionTomorrow(tomorrowSize);

                this.layoutService.setOpenExcursionsTotal(todaySize + tomorrowSize);
            });
    }

    filterTourToday(toursToday: any[]) {
        return toursToday.filter(x => x.statusLayout === 'ABIERTO').length;
    }


}
