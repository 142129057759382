import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { Sheduling } from '../class/sheduling';
import { Layout } from '../class/layout';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    public isPerson: boolean;
    public isTransport: boolean;
    public isUser: boolean;
    public isPassenger: boolean;
    public isNewUser: boolean;
    public isDriver: boolean;
    public isGuide: boolean;
    public isBank: boolean;
    public type: string;
    public isRestorePass: boolean = false;
    public isTypeDocument: boolean = true;
    public isNotTodayOrTomorrow: boolean = false;


    private selectedPersonSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedPerson$: Observable<any> = this.selectedPersonSubject.asObservable();

    private selectedTransportSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedTransport$: Observable<any> = this.selectedPersonSubject.asObservable();

    private selectedRolSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedRol$: Observable<any> = this.selectedRolSubject.asObservable();

    private selectedEstadoUserSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedEstadoUser$: Observable<any> = this.selectedEstadoUserSubject.asObservable();

    private selectedNewTransportSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedNewTransport$: Observable<any> = this.selectedNewTransportSubject.asObservable();

    private selectedScrollSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedScroll$: Observable<any> = this.selectedScrollSubject.asObservable();

    private selectedUserSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedUser$: Observable<any> = this.selectedUserSubject.asObservable();

    private selectedPassengerSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedPassenger$: Observable<any> = this.selectedPassengerSubject.asObservable();

    private selectedTypeDocumentSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    selectedTypeDocument$: Observable<any> = this.selectedTypeDocumentSubject.asObservable();

    constructor(private http: HttpClient) { }

    setSelectedPerson(person: any) {
        this.selectedPersonSubject.next(person);
    }

    setSelectedUser(user: any) {
        this.selectedUserSubject.next(user);
    }

    setSelectedEstadoUser(estadoUser: any) {
        this.selectedEstadoUserSubject.next(estadoUser);
    }

    setSelectedTransport(transport: any) {
        this.selectedTransportSubject.next(transport);
    }

    setSelectedNewTransport(NewTransport: any) {
        this.selectedNewTransportSubject.next(NewTransport);
    }

    setSelectedPassenger(person: any) {
        this.selectedPassengerSubject.next(person);  ///dfsdfsdfdfs
    }

    setSelectedTypeDocument(typeDoc: any) {
        this.selectedTypeDocumentSubject.next(typeDoc);
    }


    setSelectedRol(rol: any) {
        this.selectedRolSubject.next(rol);
    }

    setSelectedScroll(scroll: any) {
        this.selectedScrollSubject.next(scroll);
    }


    getOpenExcursionToday() {
        return parseInt(sessionStorage.getItem('today'), 10);
    }

    setOpenExcursionToday(todayTours: number) {
        sessionStorage.setItem('today', todayTours.toString());
    }

    getOpenExcursionTomorrow() {
        return parseInt(sessionStorage.getItem('tomorrow'), 10);
    }

    setOpenExcursionTomorrow(tomorrowTours: number) {
        sessionStorage.setItem('tomorrow', tomorrowTours.toString());
    }

    setOpenExcursionsTotal(toursTotal: number) {
        toursTotal ? sessionStorage.setItem('total', toursTotal.toString()) : sessionStorage.setItem('total', '0');
    }

    getOpenExcursionsTotal() {
        return parseInt(sessionStorage.getItem('total'), 10) || '0';
    }

    getToursToday(idUser: number): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/Today/${idUser}`);
    }

    getToursTomorrow(idUser: number): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/Tomorrow/${idUser}`);
    }

    getToursTomorrowLogistics(idUser: number): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/TomorrowLogistics/${idUser}`);
    }

    getToursAfterTomorrow(month: number, year: number, idUser: number): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/AfterTomorrow/${month}/${year}/${idUser}`);
    }

    /**save the scheluding */
    postScheduling(sheduling: Sheduling): Observable<Sheduling> {
        return this.http.post<Sheduling>(`/services/layout/api/Layouts/Scheduling`, sheduling);
    }

    /**update the quantity of seats */
    putSheduling(id: number, sheduling: Sheduling): Observable<Sheduling> {
        return this.http.put<Sheduling>(`/services/layout/api/Layouts/${id}`, sheduling);
    }

    /**get a list of guide */
    getGuides(): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/Guide`);
    }

    /**get a list of driver */
    getDrivers(): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/Driver`);
    }

    /**get a list of vehicle */
    getVehicles(): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/Transport`);
    }

    /**save the logistic(guide, vehicle, driver) */
    saveLayout(layout: Layout): Observable<Layout> {
        return this.http.post<Layout>(`/services/layout/api/Layouts/Layout`, layout);
    }

    /**close of excursion with logistic */
    doneLayout(layout: Layout): Observable<Layout> {
        return this.http.post<Layout>(`/services/layout/api/Layouts/LayoutClose`, layout);
    }

    /**get a list scheduling for excursion */
    getLayoutTour(id: number, destination: boolean): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/LayoutsByTour/${id}/${destination}`);
    }

    /**
     * Fuction to get open excursiones for tomorrow at 10:00 p.m.
     */
    openExcursionForTomorrow(): Observable<any[]> {
        return this.http.get<any[]>(`/services/layout/api/Layouts/OpenLayoutsTomorrow`);
    }

    /**
     * Fuction to get driver and guide data by "id" layout.
     * @param idLayout the idLayout for search data of driver and guide data.
     */
    getDriverAndGuideDataByLayout(idLayout: number): Observable<any[]> {
        return this.http.get<any[]>(`/services/layout/api/Layouts/MailByLayout/${idLayout}`);
    }

    filterLayout(layout: any): Observable<any> {
        return this.http.post<any>(`/services/layout/api/Layouts/FilterLayouts`, layout);
    }

    getAllLayouts(band: boolean): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/GetAllLayouts/${band}`);
    }

    deleteLayouts(layout: any): Observable<any> {
        return this.http.post<any>(`/services/layout/api/Layouts/DeleteLayout`, layout);
    }

    getOrigin(): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/GetOrigin`);
    }

    getService(): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/GetAllService`);
    }

    /**
     * Fuction to update layout price.
     * @param newPrice to update.
     */
    updatePriceLayout(newPrice: any) {
        return this.http.post<any>(`/services/layout/api/Layouts/UpdatePriceLayout`, newPrice);
    }

    /**
     * Fuction to update layout status.
     * @param newStatus to update.
     */
    // updateStatusLayout(newStatus: any) {
    //     return this.http.post<any>(`/services/layout/api/Layouts/UpdateStatusLayout`, newStatus);
    // }

    getTime(): Observable<any> {
        return this.http.get<any>(`/services/layout/api/Layouts/datetime`);
    }

    /**
    * Método para guardar el usuario en bd
    * @param form objeto que contiene los datos del usuario a guardar.
    */
    saveUser(form: any) {
        const url = `/services/layout/api/Staff/staff`;
        return this.http.post(url, form)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
     * Método para obtener los idiomas
     */
    getIdiomas() {
        const url = `/services/layout/api/Staff/idiomas`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
     * Método para obtener los tipos de vehículos
     */
    getTiposVehiculo() {
        const url = `/services/layout/api/Staff/tipo-vehiculo`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
     * Método para obtener las actividades
     */
    getActividades() {
        const url = `/services/layout/api/Staff/Actividades`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
    * Método para guardar datos de transporte en bd
    * @param form objeto que contiene los datos del transporte a guardar.
    */
    saveTransport(form: any) {
        const url = `/services/layout/api/Transport/transport`;
        return this.http.post(url, form)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
     * Método para obtener las marcas de los vehículos
     */
    getMarcasVehiculo() {
        const url = `/services/layout/api/Transport/marcas`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
     * Método para obtener las empresas contratistas
     */
    getEmpresasContratista() {
        const url = `/services/layout/api/Transport/empresas`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
     * Método para obtener los datos de un vehículo por patente
     */
    getTransportByPatent(patent: any) {
        const url = `/services/layout/api/Transport/patente/${patent}`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
   * Método para obtener una lista con los transporte que puede manejar el chofer
   */
    getTransportTipoVehiculo(idPerson: any) {
        const url = `/services/layout/api/Transport/transport/${idPerson}`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
   * Método para obtener datos de la tabla legal_information_transport
   */
    getLegalInformationTransport(idTransport: any) {
        const url = `/services/layout/api/Transport/transport/legal/${idTransport}`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
   * Método para obtener el listado de guias y choferes
   */
    getListGuideAndDriver() {
        const url = `/services/layout/api/Staff/list-guide-driver`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
    * Método para actualizar el usuario en bd
    * @param form objeto que contiene los datos del usuario a guardar.
    */
    updateUser(form: any) {
        const url = `/services/layout/api/Staff/update/staff`;
        return this.http.put(url, form)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
    * Método para obtener el listado de transporte
    */
    getListDataTransport() {
        const url = `/services/layout/api/Transport/list-transport`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
    * Método para obtener el listado de usuarios
    */
    getListDataUser() {
        const url = `/services/login/api/Users/GetUsersList`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
    * Método para guardar datos de usuario en bd
    * @param form objeto que contiene los datos del usuario a guardar.
    */
    saveDataUser(form: any) {
        const url = `/services/login/api/Users/CreateUser`;
        return this.http.post(url, form)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }


    /**
    * Método para blanquear contraseña de usuario en bd
    * @param body objeto que contiene los datos del usuario.
    */
    resetPassword(body: any) {
        const url = `services/login/api/Users/ClearPassword`;
        return this.http.post(url, body)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
     * Método para resetear contraseña de usuario en bd
     * @param body objeto que contiene los datos del usuario.
     */
    resetNewPassword(body: any) {
        const url = `services/login/api/Users/ChangePassword`;
        return this.http.post(url, body)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }


    /**
     * Método para obtener el listado de pasajeros
     */
    getListPassenger() {
        const url = `services/booking/api/Bookings/GetAllPassegers`;
        return this.http.get(url)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }


    /**
     * Método para obtener los datos de un pasajero por dni
     */
    getPassengerByDni(body: any) {

        const url = `services/booking/api/Bookings/GetPassengerByDni`;
        return this.http.post(url, body)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
     * Método para obtener los datos de un pasajero por número de pasaporte
     */
    getPassengerByPassport(body: any) {

        const url = `services/booking/api/Bookings/GetPassengerByPassport`;
        return this.http.post(url, body)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }


    /**
     * Método para obtener los datos de un pasajero por número de cuil/cuit
     */
    getPassengerByCuitCuil(body: any) {

        const url = `services/booking/api/Bookings/GetPassengerByCuilCuit`;
        return this.http.post(url, body)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
    * Método para guardar o actualizar datos del pasajero en bd
    * @param form objeto que contiene los datos del pasajero a guardar.
    */
    saveDataPassenger(form: any) {

        const url = `services/booking/api/Bookings/CreatePassenger`;
        return this.http.post(url, form)
            .pipe(
                map((resp: any) => {
                    return resp;
                })
            );
    }

    /**
    * Método para generar el reporte
    * @param form objeto que contiene los datos a mostrar en el reporte.
    */
    showReport(numberDate: any): Observable<Blob> {
    

        return this.http.get<any>(`/services/booking/api/Bookings/LogisticaReport/${numberDate}`, {
            responseType: 'blob' as 'json'
        });
    }

  
}
