import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth/providers/auth-guard.service';

const routes: Routes = [

    { path: '', pathMatch: 'full', redirectTo: 'login' },

    {
        path: 'login', loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule),
        data: { title: 'Login', status: true }
    },

    {
        path: 'tours', loadChildren: () => import('./tours/tours.module').then(m => m.ToursModule),
        data: { title: 'Excursiones', status: true, expectedRole: 'Admin' },
        canActivate: [AuthGuard]
    },

    {
        path: 'destination', loadChildren: () => import('./destination/destination.module').then(m => m.DestinationModule),
        data: { title: 'Listado de excursiones programadas', status: true, expectedRole: 'Admin' },
        canActivate: [AuthGuard]
    },

    {
        path: 'logistic', loadChildren: () => import('./logistic/logistic.module').then(m => m.LogisticModule),
        data: { title: 'Logistica', status: true, expectedRole: 'Admin' },
        canActivate: [AuthGuard]
    },
    {
        path: 'administracion', loadChildren: () => import('./administracion/administracion.module').then(m => m.AdministracionModule),
        data: { title: 'Administracion', status: true, expectedRole: 'Admin' },
        canActivate: [AuthGuard]
    },
    // {
    //     path: 'previous-bookings', loadChildren: './previous-bookings/previous-bookings.module#PreviousBookingsModule',
    //     data: { title: 'Reservas anteriores', status: true, expectedRole: 'Admin' },
    //     canActivate: [AuthGuard]
    // },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
