import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../auth/providers/login.service';

@Injectable()
export class AdnInterceptor implements HttpInterceptor {

    constructor(private loginService: LoginService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token: string = this.loginService.getToken();

        let request = req;

        if (token) {
            request = req.clone({
                setHeaders: {
                    authorization: `${token}`
                }
            });
        }

        return next.handle(request);
    }

}
