import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LoginContainerComponent } from './login-container/login-container.component';
import { SharedModule } from '../../shared/shared.module';
import { MaterialModule } from '../../shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxMaskModule } from 'ngx-mask';
import { LoginRoutingModule } from './login-routing.module';
import { LoginService } from '../providers/login.service';
import { AuthService } from '../providers/auth.service';
import { RoleGuardService } from '../providers/role-guard.service';
import { AuthGuardService } from '../providers/auth-guard.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { RestorePasswordComponent } from './restore-password/restore-password.component';

@NgModule({
    declarations: [
        LoginComponent,
        LoginContainerComponent,
        RestorePasswordComponent,
    ],

    imports: [
        CommonModule,
        SharedModule,
        LoginRoutingModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        NgxMaskModule.forRoot()
    ],
    providers: [
        LoginService,
        AuthService,
        RoleGuardService,
        AuthGuardService,
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService
    ]
})
export class LoginModule { }
