<div class="confirmation">

    <div class="row card-header-custom">
        <div class="col">
            <h2 class="confirmation-title" mat-dialog-title>{{configuration.title}}</h2>
        </div>
        <div class="col-sm-2">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" [mat-dialog-close]="false">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>

    <hr>

    <mat-dialog-content class="confirmation-content">{{configuration.question}}</mat-dialog-content>

    <hr>

    <mat-dialog-actions class="confirmation-actions">
        <button mat-raised-button class="new-buttom" [mat-dialog-close]="true">Aceptar</button>
    </mat-dialog-actions>
</div>
